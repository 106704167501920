div.dataTables_processing {
    background: #fff url("../images/loading_bar.gif") no-repeat scroll center 38px;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px #444;
    color: #444;
    font-size: 14px;
    height: 80px;
    left: 50%;
    margin-left: -125px;
    margin-top: -15px;
    padding: 15px 0 30px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 250px;
    z-index: 9999;
}