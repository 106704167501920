/************************* LAYOUT DEFAULTS *************************/
html {
    font-size: 16px;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.0rem;
    line-height: 1.25;
    color: #333333;
    background: #ffffff;
}

a {
    color: #8CC63F;
    text-decoration: none;
}

a:hover, a:focus {
    color: #8CC63F;
    text-decoration: underline;
}

:active { outline: none; }
:focus { outline: none; }

img {
    max-width: 100% !important;
    height: auto;
}

.content-container {
    max-width: 1280px;
    margin: auto;
}

textarea {
    resize: vertical;
}

.alert-success,
.alert-danger,
.alert-warning,
.alert-info {
    color: #fff !important;
}

.alert-success  {
    background-color: #00a65a !important;
}
.form-group.has-success label,
.form-group.has-success .help-block {
    color: #00a65a;
}
.form-group.has-success .form-control {
    border-color: #00a65a;
    box-shadow: none;
}

.alert-danger {
    background-color: #dd4b39 !important;
}
.form-group.has-error label,
.form-group.has-error .help-block {
    color: #dd4b39;
}
.form-group.has-error .form-control {
    border-color: #dd4b39;
    box-shadow: none;
}

.alert-warning {
    background-color: #f39c12 !important;
}
.form-group.has-warning label,
.form-group.has-warning .help-block {
    color: #f39c12;
}
.form-group.has-warning .form-control {
    border-color: #f39c12;
    box-shadow: none;
}

.alert-info {
    background-color: #00c0ef !important;
}
.form-group.has-info label,
.form-group.has-info .help-block {
    color: #00c0ef;
}
.form-group.has-info .form-control {
    border-color: #00c0ef;
    box-shadow: none;
}

.list-group-item-danger {
    background-color: #dd4b39;
    color: #ffffff;
}
.list-group-item-success {
    background-color: #00a65a;
    color: #ffffff;
}
.list-group-item-warning {
    background-color: #f39c12;
    color: #ffffff;
}
.list-group-item-info {
    background-color: #00c0ef;
    color: #ffffff;
}

.text-danger {
    color: #dd4b39;
}
.text-success {
    color: #00a65a;
}
.text-warning {
    color: #f39c12;
}
.text-info {
    color: #00c0ef;
}

@media (max-width: 767px) {
    .text-xs-left { text-align: left; }
    .text-xs-right { text-align: right; }
    .text-xs-center { text-align: center; }
    .text-xs-justify { text-align: justify; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: 1200px) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}

/************************* BOOTSTRAP CUSTOM *************************/
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.align-middle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.align-right-middle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/************************* TEXT DEFAULTS *************************/
h1 {
    color: #333333;
    font-size: 2.0rem;
    font-weight: normal;
    margin-bottom: 1.25rem;
}

h2 {
    color: #333333;
    font-size: 1.75rem;
    font-weight: normal;
    margin-bottom: 1.25rem;
}

h3 {
    color: #333333;
    font-size: 1.75rem;
    font-weight: normal;
    margin-bottom: 1.25rem;
}

h4 {
    color: #333333;
    font-size: 1.25rem;
    font-weight: normal;
    margin-bottom: 2.5rem;
}

p {
    font-size: 1.0rem;
}

/************************* HEADER *************************/
header {
    z-index: 5;
    margin-bottom: 0;
    border: none;
    background: #333333;
    -webkit-box-shadow: 0px 3px 3px 3px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 3px 3px 3px rgba(0,0,0,0.17);
    box-shadow: 0px 3px 3px 3px rgba(0,0,0,0.17);
}

.header-logo {
    display: inline-block;
    /*max-height: 3.125rem;*/
    max-height: 2.0rem;
    top: 50%;
    transform: translateY(50%);
}


@media (max-width: 767px) {
    .header-logo {
        transform: translateY(0%);
        margin: 0 auto;
        max-height: 2.5rem;
        top: 0;
    }
}

/************************* NAV *************************/
.navbar {
    border: none;
    margin-bottom: 0;
    text-transform: uppercase;
}

.navbar-default {
    background-color: transparent;
    border: none;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    background-color: #000000;
    color: #FFFFFF;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    background-color: #000000;
    color: #FFFFFF;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background-color: #000000;
    color: #FFFFFF;
}

.navbar-default .navbar-toggle {
    border-color: #ffffff;
    margin-right: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    border-color: #8cc63f;
    background-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar{
    background-color: #8cc63f;
}

.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: auto;
}

.navbar-nav > li > a {
    /*line-height: 3.75rem;*/
    line-height: 2.0rem;
}

.navbar-brand {
    float: left;
    height: auto;
    padding: 0;
    margin-right: 1.0rem;
}

.dropdown-menu {
    font-size: inherit;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-color: #333333;
}

.dropdown-menu > li > a {
    line-height: 2.0rem;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #000000;
    color: #FFFFFF;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
    background-color: #000000;
    color: #FFFFFF;
}

/************************* CAROUSEL *************************/
.carousel-container {
    width: 100%;
    height: 350px;
    background-color: #3C3C3C;
}

.carousel-item {
    background-image: url("../images/background-1.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100%;
}

.carousel-banner {
    background-color: #333333;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,1);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,1);
    padding: 1.0rem;
    color: #FFFFFF;
    position: absolute;
    top: 15.0rem;
    left: 0;
    text-align: left;
}

.carousel-banner h3 {
    color: #FFFFFF;
    font-weight: 700;
    margin-bottom: 1.0rem;
    margin-top: 0;
    overflow: hidden;
    text-transform: uppercase;
    white-space: nowrap;
}

.carousel-banner p {
    color: #FFFFFF;
    overflow: hidden;
    max-height: 3.75rem;
}

/************************* HIGHLIGHT *************************/
.highlight-container {
    background-color: #8CC63F;
    padding: 2.0rem 0;
}

.highlight-container h1 {
    color: #FFFFFF;
    margin-top: 0;
}

.highlight-container p {
    color: #FFFFFF;
    font-size: 1.5rem;
    margin-bottom: 0;
}

.highlight-container .read-mode {
    font-size: 1.0rem;
    font-weight: bold;
    padding: 20px 40px;
    background-color: #FFFFFF;
    color: #8CC63F;
    text-transform: uppercase;
    text-decoration: none;
}

/************************* MAIN *************************/
main {
    padding: 3.0rem 0;
    min-height: 300px;
    background-color: #FFFFFF;
}

.main-line {
    background: -moz-linear-gradient(180deg, rgba(140,198,63,0) 0%, rgba(140,198,63,1) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(140,198,63,1)), color-stop(100%, rgba(140,198,63,0))); /* safari4+,chrome */
    background: -webkit-linear-gradient(180deg, rgba(140,198,63,0) 0%, rgba(140,198,63,1) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(180deg, rgba(140,198,63,0) 0%, rgba(140,198,63,1) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(180deg, rgba(140,198,63,0) 0%, rgba(140,198,63,1) 100%); /* ie10+ */
    background: linear-gradient(270deg, rgba(140,198,63,0) 0%, rgba(140,198,63,1) 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8cc63f', endColorstr='#8cc63f',GradientType=1 ); /* ie6-9 */
    max-width: 75%;
    height: 2px;
    margin-bottom: 2.5rem;
}

.main-box {
    padding: 1.5rem;
    box-shadow: 0 0 1.0em #CCCCCC;
}

.main-box .row:not(:last-of-type) {
    margin-bottom: 3.0rem;
}

/************************* HOME *************************/
.home-box {
    padding: 1.5rem;
    box-shadow: 0 0 1.0em #CCCCCC;
    margin: 0 2.0rem;
}

.home-box h1,
.home-box h2 {
    margin-top: 0;
}

.home-box .main-line {
    margin-bottom: 1.5rem;
}

.home-box .row:not(:last-of-type) {
    margin-bottom: 3.0rem;
}

@media (max-width: 767px) {
    .home-box:not(:last-of-type) {
        margin-bottom: 2.0rem;
    }
}

.home-img {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%
}

/************************* MAPS *************************/
@media (max-width: 767px) {
    .map-container {
        height: 20.0rem;
    }
}


/************************* FOOTER *************************/
footer {
    padding: 2.0rem 0;
    background: #000000;
    color: #FFFFFF;
}

footer img {
    width: 85%;
}

footer h2 {
    color: #FFFFFF;
    margin-top: 0;
}

footer a,
footer a:hover,
footer a:focus {
    color: #FFFFFF;
}

footer .copyright {
    font-size: 0.75rem;
    margin-top: 1.0rem
}

@media (max-width: 991px) {
    footer h2 {
        margin-top: 2.0rem;
    }
}




.section-breadcrumbs a {
    color: #999999;
}

.section-toggle {
    margin: 20px 0;
}

.section-toggle .btn {
    padding: 0;
}

.toggle-item-button {
    background-color: transparent;
    text-transform: capitalize;
    color: #2b9fd9;
    font-weight: bold;
    font-size: 1.375rem;
}

.toggle-item-button:hover,
.toggle-item-button:focus {
    color: #2b9fd9;
}

.section-list {
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 40px;
}

.section-list li {
    display: block;
    margin: 0px;
    padding: 10px;
}

.section-list li:hover:not(.no-link),
.section-list li:focus {
    background: #F3F3F3;
    cursor: pointer;
    text-decoration: none;
    color: #2B9FD9;
}

.section-list li a:hover,
.section-list li a:focus {
    text-decoration: none;
}

.section-list li:not(:last-of-type) {
    border-bottom: 1px solid #F3F3F3;
}

.section-files {
    margin: 40px 0;
    background: #F3F3F3;
    border: 1px solid #666666;
    padding: 10px;
}

.section-files hr {
    border-top: 1px solid #666666;
    margin: 10px 0;
}

.jumbotron {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    margin: 30px 0;
    padding: 20px !important;
    background: #f3f3f3;
}

.jumbotron p {
    margin: 0 !important;
}

/* Banner */
.banner-container {
    width: 100%;
    height: 160px;
}

.banner-item {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}

/* Courses, Lessons */
.courses-container h3,
.lessons-container h3 {
    margin-bottom: 0;
    text-transform: uppercase;
}

/* Informations */
.informations-container h3 {
    margin-top: 0;
    text-transform: uppercase;
}

.informations-thumbnail-img {
    border: 1px solid #F3F3F3;
    width: 100%;
    padding: 20px;
}

/* Pagination */
.pagination-selector {
    font-weight: bold;
    color: #25292C;
}

.pagination-selector:not(:last-of-type) {
    margin-right: 10px;
}

.pagination-selector.active {
    color: #2b9fd9;
}





/************************* MEDIA QUERIES *************************/

/***** > XS *****/
@media only screen and (min-width: 768px) {
    /***** HEADER *****/
    ul.nav li.dropdown:hover > div.dropdown-menu {
        display: block;
    }

    .navbar-align-bottom {
        position: absolute;
        bottom: 0;
        right: 15px;
    }

    /***** MAIN *****/
    @media only screen and (min-width: 768px) {
        .informations-container .col-sm-9 {
            padding-left: 0;
            padding-right: 30px;
        }
    }
}

/***** <= XS *****/
@media only screen and (max-width: 767px) {

    /***** DEFAULTS *****/
    .content-container {
        padding: 0 15px;
    }

    /***** HEADER *****/
    header {
        padding: 10px 0;
    }

    .header-logo {
        margin: 10px auto;
    }

    .row-eq-height {
        display: block;
    }

    .nav {
        float: none;
    }

    .nav > li > a {
        padding: 0;
        margin: 15px 0;
    }

    .navbar-nav {
        margin: 0;
        text-align: center;
    }

    .navbar-nav > li > .dropdown-menu {
        margin-top: -10px;
    }

    .dropdown-menu {
        display: block;
        position: relative;
        border: none;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
    }
}